<template>
  <div class="va-row">
    <div class="flex md12 xs12">
      <vuestic-widget
        :headerText="'call-campaign.create-flow.title' | translate"
      >
        <div class="create-flow">
          <div
            class="details d-flex flex-column align-items-center"
            v-if="step === 0"
          >
            <div class="row w-100">
              <div class="col-12 mb-4">
                <label for="">{{
                  $t('call-campaign.create-flow.form.flow-name.label')
                }}</label>
                <b-form-input
                  class="border"
                  :placeholder="
                    $t('call-campaign.create-flow.form.flow-name.placeholder')
                  "
                  v-model="model.name"
                  :class="{ 'is-invalid': errors.name }"
                ></b-form-input>
                <span v-if="errors.name" class="error-message">{{
                  errors.name
                }}</span>
              </div>
              <div class="col-12 mb-4">
                <label for="">{{
                  $t('call-campaign.create-flow.form.flow-description.label')
                }}</label>
                <b-form-textarea
                  class="border"
                  :placeholder="
                    $t(
                      'call-campaign.create-flow.form.flow-description.placeholder'
                    )
                  "
                  rows="3"
                  max-rows="6"
                  v-model="model.description"
                  :class="{ 'is-invalid': errors.description }"
                ></b-form-textarea>
                <span v-if="errors.description" class="error-message">{{
                  errors.description
                }}</span>
              </div>
            </div>

            <div class="row w-100 mb-4">
              <div class="col-4">
                <label for="">{{
                  $t('call-campaign.create-flow.form.trials-count.label')
                }}</label>
                <b-form-input
                  type="number"
                  class="border"
                  min="0"
                  :placeholder="
                    $t(
                      'call-campaign.create-flow.form.trials-count.placeholder'
                    )
                  "
                  v-model="model.trialsCount"
                  :class="{ 'is-invalid': errors.trialsCount }"
                ></b-form-input>

                <span v-if="errors.trialsCount" class="error-message">
                  {{ errors.trialsCount }}
                </span>
              </div>
              <div class="col-4">
                <label for="">{{
                  $t(
                    'call-campaign.create-flow.form.minutes-to-wait-before-first-trial.label'
                  )
                }}</label>
                <b-form-input
                  type="number"
                  class="border"
                  min="0"
                  :placeholder="
                    $t(
                      'call-campaign.create-flow.form.minutes-to-wait-before-first-trial.placeholder'
                    )
                  "
                  v-model="model.minutesToWaitBeforeFirstTrial"
                  :class="{
                    'is-invalid': errors.minutesToWaitBeforeFirstTrial
                  }"
                ></b-form-input>
                <span
                  v-if="errors.minutesToWaitBeforeFirstTrial"
                  class="error-message"
                >
                  {{ errors.minutesToWaitBeforeFirstTrial }}
                </span>
              </div>
              <div class="col-4">
                <label for="">{{
                  $t(
                    'call-campaign.create-flow.form.delay-minutes-between-trials.label'
                  )
                }}</label>
                <b-form-input
                  type="number"
                  class="border"
                  min="0"
                  :placeholder="
                    $t(
                      'call-campaign.create-flow.form.delay-minutes-between-trials.placeholder'
                    )
                  "
                  v-model="model.delayMinutesBetweenTrials"
                  :class="{
                    'is-invalid': errors.delayMinutesBetweenTrials
                  }"
                ></b-form-input>
                <span
                  v-if="errors.delayMinutesBetweenTrials"
                  class="error-message"
                  >{{ errors.delayMinutesBetweenTrials }}</span
                >
              </div>
            </div>

            <div class="row w-100">
              <div class="col-12">
                <label for="">{{
                  $t('call-campaign.create-flow.form.which-call-flow-you-want')
                }}</label>
                <toggle-button
                  class="mb-4"
                  :options="callFlows"
                  v-model="selectedCallFlow"
                  @change="onCallFlowChange($event)"
                >
                  <template #cardContent="{ item }">
                    <div
                      class="card call-flow"
                      :class="{ active: item.id === selectedCallFlow.id }"
                    >
                      <img src="" alt="" />
                      <h4>{{ item.name }}</h4>
                      <p>{{ item.type }}</p>
                    </div>
                  </template>
                </toggle-button>

                <span v-if="errors.callflow" class="error-message"
                  >Call flow is required</span
                >
              </div>
            </div>

            <div class="row w-100 mb-4">
              <div class="col-12">
                <div class="row mr-5">
                  <div class="col-6">
                    <label for="">{{
                      $t('call-campaign.create-flow.form.countries.label')
                    }}</label>
                  </div>
                  <div class="col-6">
                    <label for="">{{
                      $t('call-campaign.create-flow.form.caller-ids.label')
                    }}</label>
                  </div>
                </div>
                <div v-for="(caller, idx) in model.callerIds" :key="caller.id">
                  <div class="d-flex align-items-start mb-2">
                    <div class="flex-fill">
                      <div class="row">
                        <div class="col-6">
                          <b-form-select
                            class="border"
                            :value="model.callerIds[idx].destination"
                            @change="onCountryChange($event, idx, caller.id)"
                            :class="{
                              'is-invalid':
                                caller.errors && caller.errors.length > 0
                            }"
                          >
                            <template v-slot:first>
                              <b-form-select-option disabled value="">
                                {{
                                  $t(
                                    'call-campaign.create-flow.form.countries.placeholder'
                                  )
                                }}
                              </b-form-select-option>
                            </template>
                            <b-form-select-option
                              v-for="country in countries"
                              :key="country.code"
                              :value="country.code"
                              :disabled="
                                model.callerIds[idx].destination !==
                                  country.code &&
                                  selectedCountries.includes(country.code)
                              "
                            >
                              {{ country.emoji }} {{ country.name }}
                            </b-form-select-option>
                          </b-form-select>
                          <span class="error-message">
                            {{ errors[`callerIds[${idx}].destination`] }}
                          </span>
                        </div>
                        <div class="col-6">
                          <b-form-select
                            class="border"
                            v-model="model.callerIds[idx].callerId"
                            :options="callerIds"
                            :class="{
                              'is-invalid':
                                caller.errors && caller.errors.length > 0
                            }"
                          >
                            <template v-slot:first>
                              <b-form-select-option disabled value="">
                                {{
                                  $t(
                                    'call-campaign.create-flow.form.caller-ids.placeholder'
                                  )
                                }}
                              </b-form-select-option>
                            </template>
                          </b-form-select>
                          <span class="error-message">
                            {{ errors[`callerIds[${idx}].callerId`] }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <button
                      class="btn btn-danger mx-2 px-3 py-2"
                      v-if="model.callerIds.length > 1"
                      @click="removeCaller(idx)"
                    >
                      -
                    </button>
                  </div>
                </div>
                <span class="error-message">
                  {{ errors[`callerIds`] }}
                </span>
                <button
                  class="btn btn-outline-primary btn-block py-3 mb-4"
                  @click="addNewCaller()"
                >
                  +
                </button>
              </div>
            </div>

            <button class="btn btn-primary" @click="onNext">
              {{ $t('call-campaign.create-flow.form.next') }}
            </button>
          </div>

          <div class="sound-editor d-flex flex-column" v-if="step === 1">
            <div class="body py-4 mb-4">
              <div class="d-flex flex-column">
                <div class="pre-loader--container" v-show="fileUploading">
                  <radar-spinner
                    :animation-duration="2000"
                    :size="150"
                    color="#008ecf"
                    class="m-auto"
                  />
                </div>

                <div
                  class="node-editor"
                  v-if="selectedCallFlow && !fileUploading"
                >
                  <input
                    ref="fileUpload"
                    type="file"
                    accept=".mp3,.wav"
                    hidden
                    @input="onFileChoose"
                  />
                  <node-editor :nodes="callFlow.nodes" disabled>
                    <template #node="{data, idx}">
                      <div
                        class="node d-flex flex-column align-items-center justify-content-center"
                        :class="{
                          active: activeNode.value === data.value,
                          file: data.file
                        }"
                        @click="onNodeClick(callFlow.nodes[idx])"
                        background-color="green"
                      >
                        <h5 class="m-0 capitalize mb-2">{{ data.name }}</h5>
                        <button
                          class="btn btn-secondary btn-sm"
                          @click="onSoundUpload(data)"
                        >
                          {{
                            data.file
                              ? $t(
                                  'call-campaign.create-flow.node-editor.uploaded'
                                )
                              : $t(
                                  'call-campaign.create-flow.node-editor.upload-base-sound'
                                )
                          }}
                        </button>
                      </div>
                    </template>
                  </node-editor>
                </div>
                <div class="player">
                  <sound-player
                    :soundFile="selectedFileUrl"
                    :initialMarkers="activeNode.params"
                    :markers="markers"
                    @ready="onSoundPlayerReady"
                    @statusChange="status = $event"
                    @progressChange="progress = $event"
                    @regionUpdated="onRegionUpdate"
                    @regionRemoved="onRegionRemove"
                  >
                    <template slot="actions">
                      <b-button
                        class="mx-2"
                        size="sm"
                        variant="primary"
                        @click="openPopup"
                      >
                        {{
                          $t(
                            'call-campaign.create-flow.sound-player.add-custom-variable'
                          )
                        }}
                      </b-button>
                    </template>
                  </sound-player>
                </div>
              </div>
            </div>
            <div class="foot d-flex justify-content-end">
              <button class="btn btn-primary mx-3" @click="onBack()">
                {{ $t('call-campaign.create-flow.back') }}
              </button>
              <button
                class="btn btn-primary"
                @click="onCreate"
                ref="scrollTarget"
              >
                {{ $t('call-campaign.create-flow.create') }}
              </button>
            </div>
          </div>
        </div>
      </vuestic-widget>
    </div>
    <modal :isOpen="addMarkerModalOpen" @dismiss="dismissPopup">
      <div class="d-flex flex-column align-items-center">
        <div class="head mb-4">
          <h1 class="m-0">
            {{ $t('call-campaign.create-flow.custom-variable-modal.title') }}
          </h1>
        </div>
        <div class="body d-flex flex-column align-items-center">
          <b-form-input
            v-model="markerModel.variable_name"
            class="border mb-2"
            :placeholder="
              $t(
                'call-campaign.create-flow.custom-variable-modal.form.variable-name.placeholder'
              )
            "
          ></b-form-input>
          <b-form-select
            class="border mb-2"
            :value="markerModel.type"
            :options="markerTypeOptions"
            @change="onMarkerTypeChange({ type: $event })"
          >
            <template v-slot:first>
              <b-form-select-option disabled value="">
                {{
                  $t(
                    'call-campaign.create-flow.custom-variable-modal.form.variable-type.placeholder'
                  )
                }}
              </b-form-select-option>
            </template>
          </b-form-select>
          <b-form-select
            class="border mb-2"
            v-model="markerModel.tongue"
            :options="tongueOptions"
          >
            <template v-slot:first>
              <b-form-select-option disabled :value="null">
                {{
                  $t(
                    'call-campaign.create-flow.custom-variable-modal.form.tongue.placeholder'
                  )
                }}
              </b-form-select-option>
            </template>
          </b-form-select>
          <b-button variant="primary" @click="onAddMarker">
            {{
              $t(
                'call-campaign.create-flow.custom-variable-modal.form.add-custom-variable'
              )
            }}
          </b-button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import SoundPlayer from '../../elements/SoundPlayer.vue'
import NodeEditor from '../../elements/NodeEditor.vue'
import ToggleButton from '../../elements/ToggleButton.vue'
import callCampaignService from '../../services/callCampaign.service'
import vocabService from '../../services/vocab.service'
import Modal from '../../elements/Modal'
import { RadarSpinner } from 'epic-spinners'
import { object, string, array, number } from 'yup'
import { yupToKV } from '../../utils/yup'
import { regionMargin } from '../../constants/soundwave'

export default {
  components: {
    SoundPlayer,
    Modal,
    NodeEditor,
    ToggleButton,
    RadarSpinner
  },
  data() {
    return {
      markers: [],
      markerTypeOptions: ['money', 'full-number', 'separated-number', 'text'],
      markerColors: {
        money: '#f5e',
        'full-number': '#5fe',
        'separated-number': '#f50',
        text: '#55f'
      },
      markerModel: {
        id: '',
        type: '',
        variable_name: '',
        location: '',
        tongue: 'male_1_en',
        color: '#aaa'
      },
      selectedFileUrl: '',
      waveSurfer: {},
      duration: 0,
      progress: 0,
      status: 'paused',
      callFlows: [],
      selectedCallFlow: {},
      callFlow: {},
      countries: [],
      selectedCountries: [],
      callerIds: [],
      activeNode: {},
      model: {
        name: '',
        description: '',
        callflow: '',
        callerIds: [
          {
            id: new Date().getTime(),
            destination: '',
            callerId: '',
            errors: []
          }
        ],
        trialsCount: '0',
        minutesToWaitBeforeFirstTrial: '0',
        delayMinutesBetweenTrials: '0',
        params: []
      },
      step: 0,
      addMarkerModalOpen: false,
      fileUploading: false,
      errors: {}
    }
  },
  mounted() {
    this.getCallFlows()
    this.getCountries()
    this.getCallerIds()
  },
  methods: {
    onBack() {
      this.step -= 1
    },
    async onNext() {
      if (this.model.callerIds.length > 1) {
        this.model.callerIds = this.model.callerIds.filter(
          cid => cid.destination || cid.callerId
        )
      }
      const step1Schema = object().shape({
        name: string()
          .trim()
          .required(
            this.$t('call-campaign.create-flow.errors.required.flow-name')
          ),
        description: string()
          .trim()
          .required(
            this.$t(
              'call-campaign.create-flow.errors.required.flow-description'
            )
          ),
        callflow: string()
          .trim()
          .required(
            this.$t('call-campaign.create-flow.errors.required.callflow')
          ),
        callerIds: array()
          .min(
            1,
            this.$t('call-campaign.create-flow.errors.length.caller-ids', {
              num: 1
            })
          )
          .of(
            object().shape({
              destination: string()
                .trim()
                .required(
                  this.$t('call-campaign.create-flow.errors.required.country')
                ),
              callerId: string()
                .trim()
                .required(
                  this.$t('call-campaign.create-flow.errors.required.caller-id')
                )
            })
          ),
        trialsCount: number().min(
          1,
          this.$t('call-campaign.create-flow.errors.length.trials-count', {
            num: 1
          })
        ),
        minutesToWaitBeforeFirstTrial: number().min(
          0,
          this.$t(
            'call-campaign.create-flow.errors.length.minutes-to-wait-before-first-trial',
            { num: 0 }
          )
        ),
        delayMinutesBetweenTrials: number().min(
          5,
          this.$t(
            'call-campaign.create-flow.errors.length.delay-minutes-between-trials',
            { num: 5 }
          )
        )
      })

      await step1Schema
        .validate(this.model, { abortEarly: false })
        .catch(err => {
          this.errors = yupToKV(err)
        })

      if (step1Schema.isValidSync(this.model)) {
        this.errors = {}
        this.step = 1
        this.selectedFileUrl = ''

        if (
          !(
            this.callFlow.nodes &&
            this.callFlow.nodes.length == this.selectedCallFlow.params.length
          )
        ) {
          const params = this.selectedCallFlow.params.map(param => ({
            ...param,
            call_flow_param_name: param.value,
            file: '',
            params: []
          }))
          this.callFlow.nodes = params
        }
      }
    },
    onCallFlowChange(callFlow) {
      this.model.callflow = callFlow.id
      this.selectedFileUrl = ''
      this.activeNode = {}
    },
    // step1
    async getCallFlows() {
      const result = await callCampaignService.getCallFlowTypes()
      this.callFlows = result
      this.selectedCallFlow = result[0]
      this.model.callflow = result[0].id
    },
    async getCountries() {
      const countries = await vocabService.getCountries()
      this.countries = countries
    },
    async getCallerIds() {
      const result = await vocabService.getCallerIds()
      const callerIds = result.map(cid => ({
        text: cid,
        value: cid
      }))
      this.callerIds = callerIds
    },
    addNewCaller() {
      this.model.callerIds = [
        ...this.model.callerIds,
        {
          id: new Date().getTime(),
          destination: '',
          callerId: '',
          errors: []
        }
      ]
    },
    removeCaller(idx) {
      const c = this.selectedCountries[idx]
      this.selectedCountries = this.selectedCountries.filter(el => el !== c)

      this.model.callerIds = [
        ...this.model.callerIds.slice(0, idx),
        ...this.model.callerIds.slice(idx + 1)
      ]

      this.isCallerIdsValid()
    },
    onCountryChange(value, idx) {
      this.model.callerIds[idx].destination = value
      const temp = [...this.selectedCountries]
      temp[idx] = value
      this.selectedCountries = temp
      console.log(this.selectedCountries)
    },
    // step2
    onNodeClick(node) {
      this.scrollDown()

      this.activeNode = node
      this.markers = []
      if (node.file) {
        this.selectedFileUrl = `${process.env.VUE_APP_NEW_API}sound/simple/${node.file}`
      } else {
        this.selectedFileUrl = ``
      }
    },
    async onSoundUpload(node) {
      this.$refs.fileUpload.click()
      this.activeNode = node
    },
    async onFileChoose(event) {
      const file = event.target.files[0]

      if (file) {
        const audioTypes = [
          'audio/mpeg',
          'audio/wav',
          'audio/ogg',
          'audio/midi',
          'audio/flac',
          'audio/aac',
          'audio/webm',
          'audio/x-aiff',
          'audio/aiff',
          'audio/amr',
          'audio/opus'
        ]

        if (!audioTypes.includes(file.type)) {
          this.toast(this.$t('call-campaign.create-flow.errors.wrong-format'), {
            type: 'error'
          })
        } else {
          this.fileUploading = true
          try {
            const res = await callCampaignService.uploadSound(file)
            const fileName = res.fileName

            this.callFlow.nodes = this.callFlow.nodes.map(param => {
              if (param.value === this.activeNode.value) {
                return { ...param, file: fileName }
              }
              return param
            })

            this.selectedFileUrl = `${process.env.VUE_APP_NEW_API}sound/simple/${fileName}`

            this.toast(
              this.$t('call-campaign.create-flow.toasts.file-uploaded')
            )

            this.scrollDown()
          } catch (error) {
            console.log(error)
          } finally {
            this.fileUploading = false
            event.target.value = ''
          }
        }
      }
    },
    onRegionUpdate(region) {
      const isAtEnd = region.location >= region.totalDuration - regionMargin

      if (isAtEnd) {
        this.dismissPopup()
        this.toast(
          this.$t('order-confirmation.create-flow.errors.var-at-end'),
          {
            type: 'error'
          }
        )
        // return
      }

      this.markers = this.arrayUnique(
        [...this.activeNode.params, ...this.markers],
        'id'
      ).map(marker =>
        region.id === marker.id ? { ...marker, ...region } : marker
      )
      console.log(this.markers)
      this.callFlow.nodes = this.callFlow.nodes.map(node => {
        if (node.value === this.activeNode.value) {
          return {
            ...node,
            params: this.markers.map(marker => ({
              id: marker.id,
              color: marker.color,
              location: marker.location,
              type: marker.type,
              variable_name: marker.variable_name,
              tongue: marker.tongue,
              soundDuration: marker.soundDuration
            }))
          }
        }
        return node
      })
    },
    arrayUnique(array, key) {
      return [...new Map(array.map(item => [item[key], item])).values()]
    },
    onRegionRemove(region) {
      const newMarkers = this.arrayUnique(
        [...this.activeNode.params, ...this.markers],
        'id'
      ).filter(marker => marker.id !== region.options.id)

      this.markers = newMarkers
      this.activeNode.params = newMarkers

      console.log(region)
      console.log(newMarkers)

      this.callFlow.nodes = this.callFlow.nodes.map(node => {
        if (node.value === this.activeNode.value) {
          return {
            ...node,
            params: this.markers.map(marker => ({
              id: marker.id,
              color: marker.color,
              location: marker.location,
              type: marker.type,
              variable_name: marker.variable_name,
              tongue: marker.tongue,
              soundDuration: marker.soundDuration
            }))
          }
        }
        return node
      })
    },
    async onCreate() {
      try {
        if (this.isValidParamsLocation(this.callFlow.nodes)) {
          const res = await callCampaignService.createCallFlow({
            ...this.model,
            trialsCount: +this.model.trialsCount,
            minutesToWaitBeforeFirstTrial: +this.model
              .minutesToWaitBeforeFirstTrial,
            delayMinutesBetweenTrials: +this.model.delayMinutesBetweenTrials,
            params: this.callFlow.nodes
          })
          this.$router.push({ name: 'call-campaign-flows-list' })
        } else {
          this.toast(
            this.$t('order-confirmation.create-flow.errors.var-at-end'),
            {
              type: 'error'
            }
          )
        }
      } catch (error) {
        this.toast(error.response.data.message, {
          type: 'error'
        })
      }
    },
    // utils
    onSoundPlayerReady({ waveSurfer }) {
      this.fileUploading = false

      this.waveSurfer = waveSurfer
      this.duration = waveSurfer.duration || waveSurfer.decodedData.duration
    },
    onPlayPause() {
      this.waveSurfer && this.waveSurfer.playPause()
    },
    onMarkerTypeChange({ type, variable_name }) {
      this.markerModel.type = type
      if (variable_name) this.markerModel.variable_name = variable_name
      this.markerModel.color = this.markerColors[type] || '#aaa'
    },
    onAddMarker() {
      const isAtEnd =
        this.progress >=
        (this.waveSurfer.duration || this.waveSurfer.decodedData.duration) -
          regionMargin
      if (isAtEnd) {
        this.dismissPopup()
        this.toast(this.$t('call-campaign.create-flow.errors.var-at-end'), {
          type: 'error'
        })
        return
      }

      this.markerModel.id = new Date().getTime()
      const markerExists = this.arrayUnique(
        [...this.activeNode.params, ...this.markers],
        'id'
      ).some(
        marker =>
          marker.variable_name === this.markerModel.variable_name &&
          marker.type !== this.markerModel.type
      )
      if (markerExists) {
        this.toast(
          this.$t('call-campaign.create-flow.errors.marker-exists', {
            var: this.markerModel.variable_name
          }),
          {
            type: 'error'
          }
        )
        return
      }
      if (!this.markerModel.variable_name) {
        this.toast(
          this.$t('call-campaign.create-flow.errors.required.variable-name'),
          { type: 'error' }
        )
        return
      } else {
        if (!/^[a-zA-Z][a-zA-Z0-9_]*$/.test(this.markerModel.variable_name)) {
          this.toast(
            this.$t(
              'call-campaign.create-flow.errors.variable-name-not-accepted'
            ),
            { type: 'error' }
          )
          return
        }
      }
      if (!this.markerModel.type) {
        this.toast(
          this.$t('call-campaign.create-flow.errors.required.variable-type'),
          { type: 'error' }
        )
        return
      }
      this.markers = [
        ...this.arrayUnique([...this.activeNode.params, ...this.markers], 'id'),
        {
          ...this.markerModel,
          location: this.progress,
          soundDuration:
            this.waveSurfer.duration || this.waveSurfer.decodedData.duration
        }
      ]
      this.callFlow.nodes = this.callFlow.nodes.map(node => {
        if (node.value === this.activeNode.value) {
          return {
            ...node,
            params: [
              ...node.params,
              {
                ...this.markerModel,
                location: this.progress,
                soundDuration:
                  this.waveSurfer.duration ||
                  this.waveSurfer.decodedData.duration
              }
            ]
          }
        }
        return node
      })
      this.markerModel = {
        id: '',
        type: '',
        tongue: 'male_1_en',
        variable_name: '',
        location: '',
        color: '#aaa'
      }
      this.dismissPopup()
    },
    dismissPopup() {
      this.addMarkerModalOpen = false
    },
    openPopup() {
      this.addMarkerModalOpen = true
    },
    scrollDown() {
      const element = this.$refs.scrollTarget
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
    validateOneCaller(caller) {
      if (caller) {
        caller.errors = []

        if (!caller.destination) {
          caller.errors.push('Country is required.')
        }

        if (!caller.callerId) {
          caller.errors.push('Caller ID is required.')
        }

        const duplicates = this.model.callerIds.filter(
          (c, i) =>
            caller.destination &&
            c.destination === caller.destination &&
            caller.id !== c
        )

        if (duplicates.length > 0) {
          caller.errors.push('Duplicate Country')
        }
      }
    },
    isCallerIdsValid() {
      let isValid = true

      for (const caller of this.model.callerIds) {
        this.validateOneCaller(caller)
        if (caller.errors && caller.errors.length > 0) {
          isValid = false
        }
      }

      return isValid
    },
    isValidParamsLocation(flowNodes) {
      for (var i = 0; i < flowNodes.length; i++) {
        for (var j = 0; j < flowNodes[i].params.length; j++) {
          if (
            flowNodes[i].params[j].location >=
            flowNodes[i].params[j].soundDuration - regionMargin
          ) {
            return false
          }
        }
      }
      return true
    }
  },
  computed: {
    tongueOptions() {
      return [
        {
          value: 'male_1_ar',
          text: this.$t('call-campaign.create-flow.tongues.ar')
        },
        {
          value: 'male_1_en',
          text: this.$t('call-campaign.create-flow.tongues.en')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  padding: 2rem;
  img {
    width: 64px;
    margin-bottom: 12px;
  }
  h1,
  p {
    margin: 0;
  }
  h4 {
    color: #474747;
    margin: 8px 0;
  }
  p {
    color: #929292;
  }

  &.active {
    background: #3090df;
    p,
    h4 {
      color: white;
    }
  }
}
.node {
  padding: 1rem;
  &.active {
    background-color: #3090df !important;
    color: white;
  }

  &.file {
    border-radius: 5px !important;
    border: 3px solid white !important;
    background-color: #48c748 !important;
    color: white;
  }
}

.error-message {
  color: red;
}
</style>
